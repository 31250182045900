import { useStaticQuery, graphql } from "gatsby";
import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../../components/layout";
import TeamMemberCard from "../../components/team-member-card";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import BeeBanner from "../../components/bee-banner";
import { Helmet } from "react-helmet";
import { GatsbyImage } from "gatsby-plugin-image";
import SVGFBWwa from "../../components/SVG/flying-bee-wwa";

const OurPurposeAndValues = () => {
  const data = useStaticQuery(graphql`
    query {
      heroImg: wpMediaItem(title: { eq: "our-purpose" }) {
        altText
        localFile {
          publicURL
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 100
              transformOptions: { cropFocus: CENTER, fit: CONTAIN }
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  const siteUrl = data.site.siteMetadata.siteUrl;
  const heroImg = data.heroImg.localFile.childImageSharp.gatsbyImageData;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Who We Are",
        item: {
          url: `${siteUrl}/who-we-are/our-purpose-and-values`,
          id: `${siteUrl}/who-we-are/our-purpose-and-values`,
        },
      },
    ],
  };

  if (typeof window !== "undefined") {
    return (
      <Layout>
        <Helmet>
          {" "}
          <script type="application/ld+json">
            {JSON.stringify(breadcrumb)}
          </script>
        </Helmet>

        <GatsbySeo
          title="Our Purpose And Values | Hive HR Solutions"
          description="Hive HR Solutions was founded in 2017 as a small company with big ambitions: to shake up the HR consultancy world by providing a personalised human resources support service. / placeholder"
          language="en"
          openGraph={{
            type: "website",
            url: `${siteUrl}/who-we-are`,
            title: "Our Purpose And Values | Hive HR Solutions",
            description:
              "Hive HR Solutions was founded in 2017 as a small company with big ambitions: to shake up the HR consultancy world by providing a personalised human resources support service. / placeholder",
            images: [
              {
                url: `${data.heroImg?.localFile.publicURL}`,
                width: `${data.heroImg?.localFile.childImageSharp.original.width}`,
                height: `${data.heroImg?.localFile.childImageSharp.original.height}`,
                alt: `${data.heroImg?.altText}`,
              },
            ],
          }}
        />

        <section
          id="introduction"
          className="py-5 position-relative py-md-8 py-lg-9"
        >
          {/* <SVGFBWwa
            style={{ zIndex: -2 }}
            className="position-absolute end-0 top-10 d-none d-lg-block "
          /> */}
          <Container>
            <Row>
              <Col>
                <div className="text-center">
                  <h1 className="display-3">Our purpose and values</h1>
                  <hr className="hr-primary mx-auto w-50 mb-4" />
                </div>
                <GatsbyImage
                  className=" w-100  "
                  image={heroImg}
                  alt={data.heroImg?.altText}
                ></GatsbyImage>
                <div className="mt-3">
                  <h2>Our Purpose</h2>
                  <p className="pt-4">
                    At Hive HR Solutions our purpose is to provide personalised
                    and tailored solutions that offer businesses the relief they
                    need, so they can focus on what they do best without the
                    burden of constant worry.
                  </p>
                  <p>
                    We don't just see ourselves as suppliers; we are partners
                    and an extension of your business, operating with unwavering
                    honesty, trust, and transparency. We respect and support our
                    clients without judgment, building relationships based on
                    mutual trust and respect.
                  </p>
                  <p>
                    Our team lift each other up and thrive together. We believe
                    in the power of creative thinking and understand that there
                    is no one-size-fits-all approach. We are constantly
                    evolving, staying ahead of the game, and striving for
                    excellence, because at Hive HR Solutions, standing still is
                    never an option.
                  </p>
                </div>
                <div className="mt-5">
                  <h2>Our Values</h2>
                  <ul className="pt-4">
                    <li className="mb-3">
                      Partnerships with us helps you achieve great results 
                    </li>
                    <li className="mb-3">
                      Respect is admiration for each other’s qualities 
                    </li>
                    <li className="mb-3">Team is to lift each other up </li>
                    <li className="mb-3">Tailored is the way it should be </li>
                    <li>
                      Creative is using our imagination to find you solutions 
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section>
          {" "}
          <BeeBanner
            headline="Ready to speak to us?"
            btnTxt="Call us"
            btnLink="tel:+441403627630"
          />
        </section>
      </Layout>
    );
  }
};

export default OurPurposeAndValues;
